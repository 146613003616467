<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 receptions-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="reception-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{ name: 'r_reception-desks', params: $route.params }"
          >
            <button>
              <icon icon="#cx-hea1-arrow-left" />
            </button>
          </router-link>
        </template>
        <div class="component-title" v-if="reception">
          {{
            displayLabelName(
              "visitor-management",
              "manage-reception-desks",
              "edit-reception-desk"
            )
          }}
        </div>
        <template v-slot:right>
          <button v-if="!edit" @click="edit = true">
            <icon icon="#cx-hea1-edit" />
          </button>
          <button v-if="$route.name == 'r_edit-reception'" @click="initSubmit">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <VeeForm
      v-if="reception"
      @submit="submitForm"
      :key="formKey"
      ref="editReceptionForm"
      v-slot="{ errors }"
      class="form inline-input edit-form"
      :initial-values="{
        ...reception,
        has_translations: hasTranslationsClause,
      }"
      novalidate
    >
      <ul class="clebex-item-section">
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.has_translations }"
        >
          <div class="error-message" v-if="errors.has_translations">
            {{ errors.has_translations }}
          </div>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <div class="clebex-section-input-label">
                {{
                  displayLabelName(
                    "visitor-management",
                    "manage-reception-desks",
                    "name"
                  )
                }}
              </div>
              <p class="text-label">
                {{ displayName }}
              </p>
            </div>
            <span class="follow-up-icons" v-if="edit">
              <router-link
                :to="{
                  name: 'r_edit-reception-translate-name',
                  params: $route.params,
                }"
                class="follow-up-icon-item"
              >
                <icon icon="#cx-men1-translations" />
              </router-link>
            </span>
          </div>
          <Field
            name="has_translations"
            as="input"
            type="hidden"
            rules="required"
            :value="hasTranslationsClause"
          />
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.level }"
        >
          <router-link
            v-if="edit"
            :to="{
              name: 'r_edit-reception-select-level',
              params: $route.params,
            }"
            class="clebex-item-content-wrapper"
          >
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">
                {{
                  displayLabelName(
                    "visitor-management",
                    "manage-reception-desks",
                    "level"
                  )
                }}
              </label>
              <p
                v-if="receptionLevel && receptionLevel.name"
                class="text-label"
              >
                {{ receptionLevel.name }}
              </p>
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </router-link>
          <div v-else class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">{{
                displayLabelName(
                  "visitor-management",
                  "manage-reception-desks",
                  "level"
                )
              }}</label>
              <p
                v-if="receptionLevel && receptionLevel.name"
                class="text-label"
              >
                {{ receptionLevel.name }}
              </p>
            </div>
          </div>
          <Field
            name="level_id"
            as="input"
            type="hidden"
            v-model="level_id"
            :disabled="submitting || !edit"
          />
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.parent }"
        >
          <router-link
            v-if="edit"
            :to="{
              name: 'r_edit-reception-select-parent',
              params: $route.params,
            }"
            class="clebex-item-content-wrapper"
          >
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">
                {{
                  displayLabelName(
                    "visitor-management",
                    "manage-reception-desks",
                    "parent"
                  )
                }}
              </label>
              <p
                v-if="receptionParent && receptionParent.name"
                class="text-label"
              >
                {{ receptionParent.name }}
              </p>
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </router-link>
          <div v-else class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">{{
                displayLabelName(
                  "visitor-management",
                  "manage-reception-desks",
                  "parent"
                )
              }}</label>
              <p
                v-if="receptionParent && receptionParent.name"
                class="text-label"
              >
                {{ receptionParent.name }}
              </p>
            </div>
          </div>
          <Field
            name="parent_id"
            as="input"
            type="hidden"
            v-model="parent_id"
            :disabled="submitting || !edit"
          />
        </li>
        <!-- All Containers -->
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.container }"
        >
          <router-link
            v-if="edit"
            :to="{
              name: 'r_edit-reception-select-allowed-containers',
              params: $route.params,
            }"
            class="clebex-item-content-wrapper"
          >
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">
                {{
                  displayLabelName(
                    "visitor-management",
                    "manage-reception-desks",
                    "allowed-containers"
                  )
                }}
              </label>
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </router-link>
          <div v-else class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">{{
                displayLabelName(
                  "visitor-management",
                  "manage-reception-desks",
                  "allowed-containers"
                )
              }}</label>
            </div>
          </div>
        </li>
        <!-- End All Containers -->
        <!-- Containers -->
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.container }"
        >
          <router-link
            v-if="edit"
            :to="{
              name: 'r_edit-reception-select-container',
              params: $route.params,
            }"
            class="clebex-item-content-wrapper"
          >
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">
                {{
                  displayLabelName(
                    "visitor-management",
                    "manage-reception-desks",
                    "user-container"
                  )
                }}
              </label>
              <p
                v-if="receptionContainer && receptionContainer.name"
                class="text-label"
              >
                {{ receptionContainer.name }}
              </p>
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </router-link>
          <div v-else class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">{{
                displayLabelName(
                  "visitor-management",
                  "manage-reception-desks",
                  "user-container"
                )
              }}</label>
              <p
                v-if="receptionContainer && receptionContainer.name"
                class="text-label"
              >
                {{ receptionContainer.name }}
              </p>
            </div>
          </div>
          <Field
            name="user_container_id"
            as="input"
            type="hidden"
            v-model="parent_id"
            :disabled="submitting || !edit"
          />
        </li>
        <!-- End Containers -->
        <!-- Start Language -->
        <li class="clebex-item-section-item full-right-underline">
          <div
            @click="openLanguage(language, 'r_edit-reception-language')"
            class="clebex-item-content-wrapper"
            style="cursor: pointer;"
          >
            <div class="clebex-section-input" style="cursor: pointer;">
              <label
                class="clebex-section-input-label"
                style="cursor: pointer;"
                >{{
                  displayLabelName(
                    "visitor-management",
                    "manage-reception-desks",
                    "language"
                  )
                }}</label
              >
              <p class="text-label ellipsis" style="cursor: pointer;">
                {{ language ? language.name : "" }}
              </p>
            </div>
            <span class="follow-up-icons" style="cursor: pointer;">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                ></icon>
              </span>
            </span>
          </div>
        </li>
        <!-- End Language -->
        <!-- Start Template -->
        <li class="clebex-item-section-item full-right-underline">
          <div
            style="cursor: pointer;"
            class="clebex-item-content-wrapper"
            @click="
              openDeviceTemplate(
                deviceTemplate,
                'r_edit-reception-device-template'
              )
            "
          >
            <div class="clebex-section-input" style="cursor: pointer;">
              <label
                class="clebex-section-input-label"
                style="cursor: pointer;"
                >{{
                  displayLabelName(
                    "visitor-management",
                    "manage-reception-desks",
                    "template"
                  )
                }}</label
              >
              <p class="text-label ellipsis" style="cursor: pointer;">
                {{ deviceTemplate ? deviceTemplate.name : "" }}
              </p>
            </div>
            <span class="follow-up-icons" style="cursor: pointer;">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                ></icon>
              </span>
            </span>
          </div>
        </li>
        <!-- End Template -->
        <!-- Start Default Camera -->
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label
                class="clebex-section-input-label"
                for="screen_saver_minutes"
                >{{
                  displayLabelName(
                    "visitor-management",
                    "manage-reception-desks",
                    "default-camera"
                  )
                }}</label
              >
              <Field
                id="default_camera"
                name="default_camera"
                as="input"
                type="text"
                disabled="true"
              />
              <span
                v-if="reception.default_camera"
                @click="removeDefaultCamera()"
                class="follow-up-icon-item"
                style="position: absolute; right: 15px; top: 17px; color: #999999; font-size: 15px; cursor: pointer;"
              >
                &#10005;
              </span>
            </div>
          </div>
        </li>
        <!-- End Default Camera -->
        <!-- Start Screensaver -->
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label
                class="clebex-section-input-label"
                for="screen_saver_minutes"
                >{{
                  displayLabelName(
                    "visitor-management",
                    "manage-reception-desks",
                    "screensaver"
                  )
                }}</label
              >
              <Field
                id="screen_saver_minutes"
                name="screen_saver_minutes"
                as="input"
                type="text"
                :disabled="submitting || !edit"
              />
            </div>
          </div>
        </li>
        <!-- End Screensaver -->
        <!-- Start Video -->
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="video_url">{{
                displayLabelName(
                  "visitor-management",
                  "manage-reception-desks",
                  "video"
                )
              }}</label>
              <Field
                id="video_url"
                name="video_url"
                as="input"
                type="text"
                :disabled="submitting || !edit"
              />
            </div>
          </div>
        </li>
        <!-- End Video -->
        <!-- Start Videoconference -->
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label
                class="clebex-section-input-label"
                for="video_conference_url"
                >{{
                  displayLabelName(
                    "visitor-management",
                    "manage-reception-desks",
                    "videoconference"
                  )
                }}</label
              >
              <Field
                id="video_conference_url"
                name="video_conference_url"
                as="input"
                type="text"
              />
            </div>
          </div>
        </li>
        <!-- End Videoconference -->
        <!-- Start Token -->
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="token">{{
                displayLabelName(
                  "visitor-management",
                  "manage-reception-desks",
                  "token"
                )
              }}</label>
              <Field
                id="token"
                name="token"
                as="input"
                type="text"
                :disabled="submitting || !edit"
              />
            </div>
          </div>
        </li>
        <!-- End Token -->
      </ul>
      <div
        style="width: 100%; color: #999999; padding: 10px; padding-right: 20px; text-align: right;"
      >
        <span style="cursor: pointer;" @click="deleteReceptionTokenModal = true"
          >{{
            displayLabelName(
              "visitor-management",
              "manage-reception-desks",
              "delete-tokens"
            )
          }}
          ({{ reception.used_tokens }}/{{
            reception.token ? reception.token : 0
          }})</span
        >
      </div>

      <ul class="clebex-item-section">
        <li class="clebex-item-section-item full-right-underline">
          <router-link
            v-if="edit"
            :to="{
              name: 'r_edit-reception-select-working-hours',
              params: $route.params,
            }"
            class="clebex-item-content-wrapper cursor-pointer"
          >
            <div class="clebex-section-input">
              <label
                class="cursor-pointer"
                :class="
                  workingTimeStart && workingTimeEnd
                    ? 'clebex-section-input-label'
                    : 'label'
                "
              >
                {{
                  displayLabelName(
                    "visitor-management",
                    "manage-reception-desks",
                    "start-time"
                  )
                }}
                -
                {{
                  displayLabelName(
                    "visitor-management",
                    "manage-reception-desks",
                    "end-time"
                  )
                }}
              </label>
              <p v-if="workingTimeStart && workingTimeEnd" class="text-label">
                {{ displayTime(workingTimeStart) }} -
                {{ displayTime(workingTimeEnd) }}
              </p>
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </router-link>
          <div v-else class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">
                {{
                  displayLabelName(
                    "visitor-management",
                    "manage-reception-desks",
                    "start-time"
                  )
                }}
                -
                {{
                  displayLabelName(
                    "visitor-management",
                    "manage-reception-desks",
                    "end-time"
                  )
                }}
              </label>
              <p v-if="workingTimeStart && workingTimeEnd" class="text-label">
                {{ displayTime(workingTimeStart) }} -
                {{ displayTime(workingTimeEnd) }}
              </p>
            </div>
          </div>
          <span v-if="workingTimeStart && workingTimeEnd">
            <Field
              name="working_time_start"
              as="input"
              type="hidden"
              v-model="workingTimeStart"
            />
            <Field
              name="working_time_end"
              as="input"
              type="hidden"
              v-model="workingTimeEnd"
            />
          </span>
        </li>
      </ul>

      <ul class="clebex-item-section">
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <div class="clebex-section-input-label">
                {{
                  displayLabelName(
                    "visitor-management",
                    "manage-reception-desks",
                    "code"
                  )
                }}
              </div>
              <p class="text-label" style="overflow: hidden !important;">
                {{ code }}
              </p>
            </div>
          </div>
          <span
            class="follow-up-icons"
            style="height: 0px;"
            @click="copyFieldValue(reception.code)"
          >
            <icon
              icon="#cx-app1-copy"
              style="cursor: pointer; position: relative; top: -38px;"
            ></icon>
          </span>
        </li>
      </ul>

      <ul class="clebex-item-section" v-if="edit">
        <li
          v-for="(template, index) in reception.visitor_template.filter(
            (item) => item.visible
          )"
          :key="index"
          class="clebex-item-section-item full-right-underline"
        >
          <router-link
            :to="{
              name: `r_edit-reception-email`,
              params: {
                typeId: template.visitor_template_type_id,
              },
            }"
            class="clebex-item-content-wrapper"
          >
            <div class="label">
              {{ getLabel(template.visitor_template_type_id) }}
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </router-link>
        </li>
      </ul>
      <button
        type="submit"
        ref="submitReceptionForm"
        style="display: none"
      ></button>
    </VeeForm>
    <screen-modal
      v-if="deleteReceptionTokenModal"
      class="confirm-modal"
      type="success"
      :confirm-action="deleteReceptionToken"
      :confirm-button-label="displayLabelName('global.buttons.ok')"
      :cancelButtonLabel="displayLabelName('global.buttons.cancel')"
      :show="deleteReceptionTokenModal"
      @close="deleteReceptionTokenModal = false"
    >
      <h3 class="modal-title">
        {{
          displayLabelName(
            "visitor-management",
            "messages",
            "delete-reception-desk-tokens-title"
          )
        }}
      </h3>
      <p class="text">
        {{
          displayLabelName(
            "visitor-management",
            "messages",
            "delete-reception-desk-tokens-text"
          )
        }}
        <br />
        {{ displayLabelName("global.messages.delete-action-question") }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions, mapGetters } from "vuex";
import { getLang } from "@/services/http-service";
import { DateTime } from "luxon";
import { templatesLabels } from "@/services/consts/receptions";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import languagesMixin from "@/services/mixins/languages/languages-mixin";
import deviceTemplateMixin from "@/services/mixins/device_templates/device-template";

export default {
  name: "EditReception",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
  },
  mixins: [languagesMixin, deviceTemplateMixin],
  data() {
    return {
      deleteReceptionTokenModal: false,
      submitting: false,
      edit: true,
      selfRegistration: false,
      templatesLabels: templatesLabels,
      formKey: 0,
    };
  },
  created() {
    this.init();
  },
  updated() {
    if (this.formKey === 10) {
      this.formKey = 0;
    } else {
      this.formKey++;
    }
  },
  computed: {
    ...mapState("reception", [
      "reception",
      "receptions",
      "levels",
      "containers",
      "allowedContainers",
      "receptionLevel",
      "receptionParent",
      "receptionContainer",
      "receptionsTranslations",
      "workingTimeStart",
      "workingTimeEnd",
    ]),
    ...mapState("language", ["languages"]),
    ...mapState("deviceTemplates", ["selectedDeviceTemplate"]),
    ...mapGetters("settings", ["globalTimeFormat"]),
    code() {
      if (this.reception.code.length > 40) {
        return this.reception.code.substring(0, 37) + "...";
      }

      return this.reception.code;
    },
    receptionId() {
      const { receptionId } = this.$route.params;
      return receptionId;
    },
    level_id() {
      if (this.receptionLevel && this.receptionLevel.id) {
        return this.receptionLevel.id;
      } else if (this.reception && this.reception.level_id) {
        return this.reception.level_id;
      }
      return null;
    },
    parent_id() {
      if (this.receptionParent && this.receptionParent.id) {
        return this.receptionParent.id;
      }
      return null;
    },
    user_container_id() {
      if (this.receptionContainer && this.receptionContainer.id) {
        return this.receptionContainer.id;
      }
      return null;
    },
    displayReceptionName() {
      const { reception } = this;
      const locale = getLang();
      if (
        reception &&
        reception.translations &&
        reception.translations.length
      ) {
        const filtered = reception.translations.filter(
          (item) => item.locale === locale
        );
        if (filtered && filtered.length && filtered[0].attributes) {
          return filtered[0].attributes.name;
        } else {
          return reception.name;
        }
      } else {
        return "";
      }
    },
    displayReceptionSavedName() {
      const { receptionsTranslations } = this;
      const locale = getLang();
      if (receptionsTranslations && receptionsTranslations.length) {
        const filtered = receptionsTranslations.filter(
          (item) => item.locale === locale
        );
        if (filtered && filtered.length && filtered[0].attributes) {
          return filtered[0].attributes.name;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    displayName() {
      return this.displayReceptionSavedName || this.displayReceptionName;
    },
    hasTranslationsClause() {
      const { reception, languages, receptionsTranslations } = this;
      if (languages && languages.data && languages.data.length) {
        return (
          languages.data.length === receptionsTranslations.length ||
          (reception &&
            reception.translations &&
            reception.translations.length === languages.data.length)
        );
      }
      return false;
    },
    language() {
      if (this.selectedLanguage) {
        return this.selectedLanguage;
      } else {
        if (this.reception) {
          return this.getLanguage(this.reception.language_id);
        }
        return null;
      }
    },
    deviceTemplate() {
      if (this.selectedDeviceTemplate) {
        return this.selectedDeviceTemplate;
      } else {
        if (this.reception) {
          return this.getDeviceTemplate(this.reception.device_template_id);
        }
        return null;
      }
    },
  },
  methods: {
    ...mapActions("reception", [
      "getReception",
      "getReceptions",
      "getLevels",
      "updateReception",
      "setReceptionLevel",
      "getContainers",
      "setReceptionContainer",
      "getContainerAccessTypes",
      "getAllowedContainers",
    ]),
    ...mapActions("language", ["setSelectedLanguage"]),
    ...mapActions("deviceTemplates", ["setSelectedDeviceTemplate"]),
    deleteReceptionToken() {
      this.$store.commit("loader/setScreenLoading", true, {
        root: true,
      });

      httpServiceAuth
        .get(
          `${apiEndpoints.company.receptionDesks}/${this.reception.id}/clear-tokens`
        )
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.getReception(this.$route.params.receptionId);
          this.$store.commit("loader/setScreenLoading", false, {
            root: true,
          });
        });
    },
    copyFieldValue(value) {
      const el = document.createElement("textarea");
      el.value = value;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    },
    getLabel(id) {
      const template = this.templatesLabels.find(
        (template) => template.id === id
      );
      return this.displayLabelName(
        "visitor-management",
        "manage-reception-desks",
        template.label
      );
    },
    removeDefaultCamera() {
      this.reception.default_camera = null;
    },
    init() {
      if (this.$route.name === "r_edit-reception") {
        this.setSelectedLanguage(null);
        this.setSelectedDeviceTemplate(null);
        const promises = [];
        if (!this.levels) {
          promises.push(this.getLevels());
        }
        if (!this.receptions) {
          promises.push(this.getReceptions());
        }
        promises.push(
          this.getContainerAccessTypes().then(() => {
            this.getContainers().then(() => {
              this.getAllowedContainers(this.receptionId);
            });
          })
        );

        promises.push(this.getReception(this.receptionId));

        return Promise.all(promises).then(() => {
          const level = this.levels.find(
            (lvl) => lvl.id == this.reception.level_id
          );
          this.selfRegistration = Boolean(this.reception.self_registration);

          if (level) {
            this.$store.commit("reception/setReceptionLevel", level, {
              root: true,
            });
          }
          let parent = this.receptions.find(
            (p) => p.id == this.reception.parent_id
          );

          if (parent === undefined || !parent) {
            parent = null;
          }
          this.$store.commit("reception/setReceptionParent", parent, {
            root: true,
          });

          let container = null;
          if (this.containers && this.containers.length) {
            container = this.containers.find(
              (p) => p.id == this.reception.user_container_id
            );
          }

          if (container === undefined || !container) {
            container = null;
          }
          this.$store.commit("reception/setReceptionContainer", container, {
            root: true,
          });
          this.$store.commit(
            "reception/setWorkingTimeStart",
            this.reception.working_time_start,
            {
              root: true,
            }
          );
          this.$store.commit(
            "reception/setWorkingTimeEnd",
            this.reception.working_time_end,
            {
              root: true,
            }
          );
        });
      }
    },
    initSubmit() {
      this.$refs.submitReceptionForm.click();
    },
    submitForm(data) {
      const receptionObj = { ...data };
      receptionObj.allowed_user_container_ids = this.allowedContainers.map(
        (el) => el.id
      );
      receptionObj.self_registration = Number(this.selfRegistration);
      receptionObj.visitor_template = this.reception.visitor_template;
      receptionObj.level_id = this.receptionLevel.id;
      receptionObj.parent_id = this.receptionParent
        ? this.receptionParent.id
        : null;
      receptionObj.user_container_id = this.receptionContainer
        ? this.receptionContainer.id
        : null;
      receptionObj.translations = this.receptionsTranslations;

      receptionObj.language_id = this.language ? this.language.id : null;
      receptionObj.device_template_id = this.deviceTemplate
        ? this.deviceTemplate.id
        : null;

      this.updateReception({ data: receptionObj, id: this.reception.id }).then(
        () => {
          this.$router.push({
            name: "r_edit-reception",
            params: this.$route.params,
          });
          this.getReception(this.reception.id);
        }
      );
    },
    getTemplate(id) {
      return this.reception.visitor_template.find(
        (template) => template.visitor_template_type_id == id
      );
    },
    displayTime(time) {
      if (!time) {
        return;
      }
      // Luxon fix for am/pm... remove when globalDateFormat is Luxon oriented
      let timeFormat = this.globalTimeFormat;
      if (timeFormat.slice(-1) === "A") {
        timeFormat = timeFormat.replace(/.$/, "a");
      }
      return DateTime.fromISO(time).toFormat(timeFormat || "HH:mm");
    },
  },
  watch: {
    receptionId: {
      imediate: true,
      handler() {
        this.init();
      },
    },
    reception: {
      deep: true,
      handler() {
        this.formKey++;
      },
    },
  },
  beforeUnmount() {
    this.$store.commit("reception/setReceptionLevel", null, {
      root: true,
    });
    this.$store.commit("reception/setReceptionParent", null, {
      root: true,
    });
    this.$store.commit("reception/setReceptionContainer", null, {
      root: true,
    });
    this.$store.commit("reception/setReceptionTranslations", [], {
      root: true,
    });
    this.$store.commit("reception/setWorkingTimeStart", null, {
      root: true,
    });
    this.$store.commit("reception/setWorkingTimeEnd", null, {
      root: true,
    });
  },
};
</script>
